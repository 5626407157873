import React, {useRef, useState, useEffect} from 'react';
import FAQ from "../Components/FAQ";
import { useAppContext } from '../libs/contextLib';
import Activities from '../Components/Activites';

export default function Home() {
    const {isMobile} = useAppContext();	
    
    useEffect(() => {
    },[isMobile]);

    return(
        <>
        <section className="banner">
            <div className="container">
                <img src={require("../assets/images/grab-moveit-logo.png")} alt="Grab and MoveIt logo" className='grab-moveit-logo'/>
                {!isMobile ?
                    <img src={require("../assets/images/funmilya-logo.png")} alt="Funmilya Day 2024" className='funmilya-logo'/>
                    :
                    <img src={require("../assets/images/funmilya-logo-mobile.png")} alt="Funmilya Day 2024" className='funmilya-logo'/>
                }
                <p>
                    Register na and let's celebrate our <br />
                    Grab and Move It Tatay, Papa, Dad, Daddy as one big family!
                </p>
                <div className="banner-activities-wrapper">
                    <img src={require("../assets/images/banner-UnliRides.png")} alt="Unli rides"/>
                    <img src={require("../assets/images/banner-ExcitingRaffles.png")} alt="Exciting raffles"/>
                    <img src={require("../assets/images/banner-BigatingPerformances.png")} alt="Grad Blowout"/>
                </div>
            </div>
        </section>
        <section className="schedule">
            <div className="container">
                <div className="hook">
                    <div className='text-container'>
                        <p>
                            Pakain, pa-rides, pa-raffle at pa-concert!<br /> 
                            Eto ang nagaantay sa inyo sa <b>FUNMILYA DAY 2024</b>
                        </p>
                    </div>
                </div>
                <div className="table-wrapper">
                    <table>
                        <tr>
                            <td>8:00-10:00 AM</td>
                            <td>Registration</td>
                        </tr>
                        <tr>
                            <td>10:00-10:05 AM</td>
                            <td>Opening Performance</td>
                        </tr>
                        <tr>
                            <td>10:05-10:10 AM</td>
                            <td>Host's Opening Sales</td>
                        </tr>
                        <tr>
                            <td>10:10-10:15 AM</td>
                            <td>Welcome Remarks</td>
                        </tr>
                        <tr>
                            <td>10:15-10:30 AM</td>
                            <td>Shell Partner Talk</td>
                        </tr>
                        <tr>
                            <td>10:30-10:35 AM</td>
                            <td>Game</td>
                        </tr>
                        <tr>
                            <td>10:35-10:50 AM</td>
                            <td>Grab Finance Talk</td>
                        </tr>
                        <tr>
                            <td>10:50-10:55 AM</td>
                            <td>Game</td>
                        </tr>
                        <tr>
                            <td>10:55-11:10 AM</td>
                            <td>Partner Cover Plus Talk</td>
                        </tr>
                        <tr>
                            <td>11:10-11:15 AM</td>
                            <td>Raffle Draw</td>
                        </tr>
                        <tr>
                            <td>11:15-11:20 AM</td>
                            <td>Dance Performance</td>
                        </tr>
                        <tr>
                            <td>11:20-1:30 PM</td>
                            <td>Break</td>
                        </tr>
                    </table>
                    <table>
                        <tr>
                            <td>1:30-1:35 PM</td>
                            <td>Host's Introduction</td>
                        </tr>
                        <tr>
                            <td>1:35-1:40 PM</td>
                            <td>Opening Message</td>
                        </tr>
                        <tr>
                            <td>1:40-2:10 PM</td>
                            <td>Grab and Move It Graduation</td>
                        </tr>
                        <tr>
                            <td>2:10-2:40 PM</td>
                            <td>Acrobat Performance</td>
                        </tr>
                        <tr>
                            <td>2:40-2:45 PM</td>
                            <td>Game</td>
                        </tr>
                        <tr>
                            <td>2:45-3:15 PM</td>
                            <td>Magician Performance</td>
                        </tr>
                        <tr>
                            <td>3:15-3:20 PM</td>
                            <td>Game</td>
                        </tr>
                        <tr>
                            <td>3:20-3:50 PM</td>
                            <td>Puppet Show</td>
                        </tr>
                        <tr>
                            <td>3:50-3:55 PM</td>
                            <td>Game</td>
                        </tr>
                        <tr>
                            <td>3:55-6:30 PM</td>
                            <td>Break</td>
                        </tr>
                        <tr>
                            <td>6:30-6:40 PM</td>
                            <td>Raffle Draw</td>
                        </tr>
                        <tr>
                            <td>6:40-7:10 PM</td>
                            <td>Guest Performance</td>
                        </tr>
                    </table>
                </div>
            </div>
        </section>
        <section className="activities">
            <div className="container">
                <Activities/>
            </div>
        </section>
        <section className="reminders">
            <div className="container">
                <img src={require("../assets/images/reminders-title.png")} alt="Mga Dapat Ihanda" className="title" />
                <h1>
                    <b>PAGHANDA NG MGA KAGAMITAN:</b><br/>
                    <h3 className='pt-0'>
                        Tiyaking meron kayong mga kagamitang kailangan para sa isang maginhawang Family Day. <br /> Ito ay maaaring kasama ang mga sumusunod:
                    </h3>
                </h1>

                {!isMobile ?
                    <img src={require("../assets/images/reminders-collection.png")} alt="Reminders" className="reminders-photo" />
                    :
                    <img src={require("../assets/images/reminders-collection-mobile.png")} alt="Reminders" className="reminders-photo" />
                }
                <h3>
                    Higit sa lahat, paghandaan ang araw na puno ng kasiyahan. Ihanda ang sarili at <br />
                    ang inyong pamilya upang mag-enjoy, mag-explore, at magbuo ng mga masayang alaala sa <br />
                    <b>Ka-Grab at Move it Funmilya Day 2024: Todo Saya as One Grab Family.</b>
                </h3>
            </div>
        </section>
        <section className="faq">
            <img src={require("../assets/images/bgTopper.png")} alt="" className="bgTopper" />
            <div className="container">
                <img src={require("../assets/images/faq-title.png")} alt="FAQs" className="title" />
                <FAQ/>
            </div>
        </section>
        <section className="partners">
            <div className="container">
                <div className="header">
                    <h1>MARAMING SALAMAT</h1>
                    <h2>SA ATING MGA PARTNERS</h2>
                </div>
                <div className="partner-logos">
                    <div className="wrapper major">
                        <img src={require("../assets/images/text-majorSponsors.png")} alt="Minor Sponsors" className="title" />
                        <img src={require("../assets/images/logos-majorSponsors.png")} alt="Minor Sponsors" className="logos" />
                    </div>
                    <div className="divider">
                        <div></div>
                    </div>
                    <div className="wrapper copresenters">
                        <img src={require("../assets/images/text-coPresenters.png")} alt="Co Presenters" className="title" />                        <img src={require("../assets/images/logos-coPresenters.png")} alt="Co Presenters" className="logos" />

                    </div>
                    <div className="divider">
                        <div></div>
                    </div>
                    <div className="wrapper minor">
                        <img src={require("../assets/images/text-minorSponsors.png")} alt="Minor Sponsors" className="title" />
                        <img src={require("../assets/images/logos-minorSponsors.png")} alt="Minor Sponsors" className="logos" />
                    </div>
                </div>
            </div>
        </section>
        </>
    );
}