import React, {useEffect} from 'react';
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom"
import Home from './Containers/Home';

export default function Router(props) {
    const location = useLocation();
    useEffect(() => {
        props.reactGA.send({hitType:"pageview", page: location.pathname});
        console.log('called');
        console.log("here")
    }, [location]);
    return (
        <Routes>								
            <Route path="/" reactGA={props.reactGA} element={<Home/>} />
        </Routes>
    );
}