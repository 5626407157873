import React, {StrictMode, useState, useEffect} from 'react';
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom"
import AppRoutes from "./Router";
import ReactGA from "react-ga4";
import "./assets/styles/fonts.css";
import "./assets/styles/layout.scss";
import "./assets/styles/main.scss";
import "./assets/styles/mobile.scss";
import 'bootstrap/dist/css/bootstrap.min.css';
import {AppContext} from './libs/contextLib';
ReactGA.initialize('G-4S4FXHVCPD');

function debounce(fn, ms) {
    let timer
    return _ => {
    clearTimeout(timer)
    timer = setTimeout(_ => {
        timer = null
        fn.apply(this, arguments)
    }, ms)
    };
}

function App() {
    const [dimensions, setDimensions] = useState({ 
        height: window.innerHeight,
        width: window.innerWidth
    })
    const [isMobile, setIsMobile] = useState(window.innerWidth < 901 ? true : false);
    useEffect(() => {
        const debouncedHandleResize = debounce(function handleResize() {
            setDimensions({
            height: window.innerHeight,
            width: window.innerWidth
            });

            console.log(window.innerWidth < 901)
            if(window.innerWidth < 901){setIsMobile(true)}else{setIsMobile(false)};
        }, 1000)
    
        window.addEventListener('resize', debouncedHandleResize);
    }, [isMobile]);



    return ( 
        <StrictMode>
            <AppContext.Provider value={{isMobile}}>
                <main><AppRoutes reactGA={ReactGA} /></main> 
            </AppContext.Provider>
        </StrictMode>  
    );
}

export default App;
