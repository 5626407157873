import React, {useEffect} from 'react';
import { useAppContext } from '../libs/contextLib';
export default function Activities() {
    const {isMobile} = useAppContext();	
    return(
        <>
        {!isMobile ?
            <table className="activities-table">
                <tr>
                    <td><img src={require("../assets/images/activities-UnliRides.png")} alt="Unli rides" className='title'/></td>
                    <td><img src={require("../assets/images/activities-ExcitingRaffles.png")} alt="Exciting raffles" className='title'/></td>
                    <td><img src={require("../assets/images/activities-BigatingPerformances.png")} alt="Grad blowout" className='title'/></td>
                </tr>
                <tr>
                    <td>
                        <p className="desc">
                            Sakay lang nang sakay! Roller coaster, ferris wheel, at iba pa - tuloy tuloy lang ang saya ng buong pamilya dahil may unlimited rides para sa lahat sa Star City habang ginaganap ang Grab and Move It <b>FUNMILYA DAY 2024</b>.
                        </p>
                    </td>
                    <td>
                        <p className="desc">
                             May chance kang manalo ng mga exciting na premyo sa FUNMILYA DAY 2024 Raffle! Free domestic flights, hotel stays, cash prizes at marami pang ibang sorpresa at freebies mula sa ating mga event sponsors!
                        </p>
                    </td>
                    <td>
                        <p className="desc">
                            Celebration na rin ito para sa mga Ka-Grab, Ka-Tropa, at kapamilya nila na nakapagtapos ng pag-aaral ngayong June! Magbibigay pugay tayo sa inyong pagsisikap sa pamamagitan ng special segment sa ating programa, freebies, raffle, at iba pa!
                        </p>
                    </td>
                </tr>
                <tr className='tr-kv'>
                    <td><img src={require("../assets/images/rides-new.png")} className='kv' alt="Funmilya Rides"/></td>
                    <td><img src={require("../assets/images/prizes-new.png")} className='kv' alt="Funmilya Raffle Prizes"/></td>
                    <td><img src={require("../assets/images/guests-new.png")} className='kv' alt="Funmilya Guests"/></td>
                </tr>
            </table>
        :
            <div className="activities-wrapper">
                <img src={require("../assets/images/activities-UnliRides.png")} alt="Unli rides" className='title'/>
                <p className="desc">
                    Sakay lang nang sakay! Roller coaster, ferris wheel, at iba pa - tuloy tuloy lang ang saya ng buong pamilya dahil may unlimited rides para sa lahat sa Star City habang ginaganap ang Grab and Move It <b>FUNMILYA DAY 2024</b>.
                </p>
                <img src={require("../assets/images/rides.png")} className='kv' alt="Funmilya Rides"/>

                <img src={require("../assets/images/activities-ExcitingRaffles.png")} alt="Exciting raffles" className='title'/>
                <p className="desc">
                     May chance kang manalo ng mga exciting na premyo sa FUNMILYA DAY 2024 Raffle! Free domestic flights, hotel stays, cash prizes at marami pang ibang sorpresa at freebies mula sa ating mga event sponsors!
                </p>
                <img src={require("../assets/images/prizes-new.png")} className='kv' alt="Funmilya Raffle Prizes"/>

                <img src={require("../assets/images/activities-BigatingPerformances.png")} alt="Grad blowout" className='title'/>
                <p className="desc">
                    Celebration na rin ito para sa mga Ka-Grab, Ka-Tropa, at kapamilya nila na nakapagtapos ng pag-aaral ngayong June! Magbibigay pugay tayo sa inyong pagsisikap sa pamamagitan ng special segment sa ating programa, freebies, raffle, at iba pa!
                </p>
                <img src={require("../assets/images/guests.png")} className='kv' alt="Funmilya Guests"/>
            </div>
        }
        </>
    );
}