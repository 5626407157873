import Accordion from 'react-bootstrap/Accordion';
import React, {useEffect} from 'react';
export default function FAQ() {
    return(
    <Accordion alwaysOpen>
        <Accordion.Item eventKey='faq-1' id="faq-1">
            <div className="faq-button">
                <Accordion.Header>
                    Sino ang qualified na makasama sa Ka-Grab at Move it Funmilya Day 2024: Todo Saya as One Grab Family?
                    &nbsp;&nbsp;&nbsp;
                </Accordion.Header>
            </div>
            <Accordion.Body>
                <div className="faq-answer">
                    Ang mga qualified ay ang mga top performing drivers sa naganap na Rides Challenge noong June 5-11, 2024. Ito ay vinalidate at vinerify ng Grab at Move It team base sa metrics. <br />
                    May ilang slots rin ang mga napili noong Graduation Blowout sign ups. 
                </div>
            </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey='faq-2' id="faq-2">
            <div className="faq-button">
                <Accordion.Header>
                    Paano malalaman kung ako ay qualified para sa ticket?
                    &nbsp;&nbsp;&nbsp;
                </Accordion.Header>
            </div>
            <Accordion.Body>
                <div className="faq-answer">
                    Ikaw ay makaka-tanggap ng confirmation message at QR code sa iyong email address.<br />
                    <b>
                        TANDAAN: Ang mga papadalhan ng sign up form ay ang mga top performing Grab drivers/riders at Move It riders sa rides challenge, o napili sa Graduation Blowout sign ups. Kung kayo ay kabilang dito, makakatanggap kayo ng message sa iyong driver app patungkol sa Funmilya Day registration by June 15.
                    </b>
                </div>
            </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey='faq-3' id="faq-3">
            <div className="faq-button">
                <Accordion.Header>
                    Ilang ang pwedeng isama ng qualified na Driver?
                    &nbsp;&nbsp;&nbsp;
                </Accordion.Header>
            </div>
            <Accordion.Body>
                <div className="faq-answer">
                    Maari magsama ang driver ng hanggang 2 companions. 
                </div>
            </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey='faq-4' id="faq-4">
            <div className="faq-button">
                <Accordion.Header>
                    Paano makukuha ang mga tickets?
                    &nbsp;&nbsp;&nbsp;
                </Accordion.Header>
            </div>
            <Accordion.Body>
                <div className="faq-answer">
                    Ang mga tickets ay maaring makuha sa Star City <b>Ka-Grab at Move it Funmilya Day 2024: Todo Saya as One Grab Family</b> sa June 19, 2024.
                </div>
            </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey='faq-5' id="faq-5">
            <div className="faq-button">
                <Accordion.Header>
                    Ako ay hindi makakapunta sa kung anong dahilan, maaari bang ibang tao na lamang ang gumamit ng aking ticket?
                    &nbsp;&nbsp;&nbsp;
                </Accordion.Header>
            </div>
            <Accordion.Body>
                <div className="faq-answer">
                    Ang iyong slot ay nakareserve para sa iyo at iyong pamilya na ni-registerlamang. Ito ay hindi maaaring ibigay sa iba.
                </div>
            </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey='faq-6' id="faq-6">
            <div className="faq-button">
                <Accordion.Header>
                    Maaari bang palitan ng cash o ibang ticket sa ibang araw kung ako ay hindi makakapunta?
                    &nbsp;&nbsp;&nbsp;
                </Accordion.Header>
            </div>
            <Accordion.Body>
                <div className="faq-answer">
                    Ang iyong ticket ay hindi convertible to cash at ‘di na pwedeng gamitin sa iba pang araw.
                </div>
            </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey='faq-7' id="faq-7">
            <div className="faq-button">
                <Accordion.Header>
                    Mayroon bang age requirements?
                    &nbsp;&nbsp;&nbsp;
                </Accordion.Header>
            </div>
            <Accordion.Body>
                <div className="faq-answer">
                    Kung maaari, isama lamang ang mga batang may edad 7-65 at siguraduhin sila ay physically fit. Kung may dalang mga batang below 7 years old, mag-ingat at tandaan ang mga patakaran sa bawat atraksyon. Alamin ang limitasyon upang hindi mapahamak o masaktan sa activities o atraksyon sa loob ng park.
                </div>
            </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey='faq-8' id="faq-8">
            <div className="faq-button">
                <Accordion.Header>
                    Maaari bang magdala ng pagkain at inumin sa loob ng Park?
                    &nbsp;&nbsp;&nbsp;
                </Accordion.Header>
            </div>
            <Accordion.Body>
                <div className="faq-answer">
                    Ipinagbabawal ang pagdala ng sariling pagkain at inumin bukod sa tubig sa loob ng Star City. May mga available na kainan at tindahan na nag-aalok ng mga pagkain at inumin na maaaring pagbilhan gamit ang iyong Star cash.
                </div>
            </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey='faq-9' id="faq-9">
            <div className="faq-button">
                <Accordion.Header>
                    Kung may gusto akong isama, maaari ba silang magwalk-in?
                    &nbsp;&nbsp;&nbsp;
                </Accordion.Header>
            </div>
            <Accordion.Body>
                <div className="faq-answer">
                    Ang bawat Ka-Grab/ Ka-Tropa ay maaring magsama hanggang 2 companions kung ikaw ay may mayroon additional na kasama maaring bumili ng ticket sa loob ng star city. <br />
                    <b>Paalala: Ipakita ang confirmation message at Valid ID upang ma-avail ang discount.</b>
                </div>
            </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey='faq-10' id="faq-10">
            <div className="faq-button">
                <Accordion.Header>
                    Bukod sa ticket, ano pa ang makukuha ng bawat Qualified Driver sa araw ng event?
                    &nbsp;&nbsp;&nbsp;
                </Accordion.Header>
            </div>
            <Accordion.Body>
                <div className="faq-answer">
                    <table>
                        <tr>
                            <td>Driver with 1 Companion</td>
                            <td>
                                <ul>
                                    <li>&#x2022; Php 300 worth of Star City Star Cash Voucher</li>
                                    <li>&#x2022; 2 Hotdog Food Stubs and 2 Bottled Water</li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>Driver with 2 Companion</td>
                            <td>
                                <ul>
                                    <li>&#x2022; Php 500 worth of Star City Star Cash Voucher</li>
                                    <li>&#x2022; 3 Hotdog Food Stubs and 3 Bottled Water</li>
                                </ul>
                            </td>
                        </tr>
                    </table>
                </div>
            </Accordion.Body>
        </Accordion.Item>
    </Accordion>
    );
}